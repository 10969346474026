<template>
  <div class="home-container">
    <header data-role="Header" class="home-header">
      <img alt="logo" src="/mecaca%20reviews-1500h.png" class="home-image" />
      <div class="home-btn-group">
        <a
          href="https://reviews.mecaca.my/#/"
          target="_blank"
          rel="noreferrer noopener"
          class="home-login button"
        >
          Login
        </a>
        <a
          href="https://timesync.novocall.co/mecaca/mecaca-sales-meeting/"
          target="_blank"
          rel="noreferrer noopener"
          class="home-register button"
        >
          SCHEDULE A DEMO
        </a>
      </div>
      <div data-role="BurgerMenu" class="home-burger-menu">
        <navigation-links rootClassName="rootClassName11"></navigation-links>
      </div>
    </header>
    <div class="home-hero">
      <div class="home-container01">
        <h1 class="home-heading">IMPROVE YOUR ONLINE REPUTATION</h1>
        <span class="home-text">
          <span>
            Analyze, generate, monitor and share online reviews to improve your
            company&apos;s reputation and attract new clients.
          </span>
          <br />
          <span><span v-html="rawbnqo"></span></span>
          <span><span v-html="rawf1w9"></span></span>
        </span>
        <div class="home-btn-group1">
          <a
            href="https://client.mecaca.com/subscribe/6410808d7a842740cfea825b/mecaca-reviews-monthly"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link button"
          >
            Start 14 days Free Trial
          </a>
        </div>
      </div>
      <img
        alt="image"
        src="/group-4319%20%5B1%5D-1500h.png"
        class="home-image01"
      />
    </div>
    <div class="home-features">
      <h1 class="home-text05">USED BY 300+ LOCAL BUSINESSES WORLDWIDE</h1>
      <div class="home-container02">
        <img alt="image" src="/soundlife-hearing-200h.png" class="home-image02" />
        <img alt="image" src="/ydyw-1-200h.png" class="home-image03" />
        <img alt="image" src="/health%20shine-200h.png" class="home-image04" />
        <img alt="image" src="/gi-logo-200h.png" class="home-image05" />
        <img alt="image" src="/hamel%20%5B1%5D-200h.png" class="home-image06" />
        <img alt="image" src="/artweme-200h.png" class="home-image07" />
      </div>
    </div>
    <div class="home-hero1">
      <div class="home-container03">
        <span class="home-text06">GET REVIEWS</span>
        <h1 class="home-text07">
          GET NEW POSITIVE REVIEWS, AVOID THE NEGATIVE ONES
        </h1>
        <span class="home-text08">
          <span>
            Get new reviews by inviting your customers to review you via
            customizable e-mail or SMS, or on-site via QR code.
          </span>
          <br />
          <br />
          <span>
            Protect your reputation with an optional system that captures
            dissatisfied customers, keeping negative feedback private.
          </span>
          <br />
          <br />
          <span>
            Choose on which platforms you want to increase the number of positive
            reviews.
          </span>
        </span>
        <a
          href="https://client.mecaca.com/subscribe/6410808d7a842740cfea825b/mecaca-reviews-monthly"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link01 button"
        >
          Start 14 days Free Trial
        </a>
      </div>
      <img
        alt="image"
        src="/group-3693-6%20%5B1%5D-1400w.png"
        class="home-image08"
      />
    </div>
    <div class="home-hero2">
      <img
        alt="image"
        src="/group-4349-2-1%20%5B1%5D-1500h.png"
        class="home-image09"
      />
      <div class="home-container04">
        <span class="home-text16">MONITOR AND REPLY</span>
        <h1 class="home-text17">
          MONITOR AND REPLY TO ALL THE REVIEWS FROM ONE PLACE.
        </h1>
        <span class="home-text18">
          <span>
            View all reviews received in the various related review platforms, day
            by day.
          </span>
          <br />
          <br />
          <span>
            Be sure to respond to all reviews you receive to show closeness to
            your customers.
          </span>
          <br />
          <br />
          <span>
            Respond to private negative feedback to regain your customers&apos;
            trust, and figure out how to improve.
          </span>
        </span>
        <a
          href="https://client.mecaca.com/subscribe/6410808d7a842740cfea825b/mecaca-reviews-monthly"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link02 button"
        >
          Start 14 days Free Trial
        </a>
      </div>
    </div>
    <div class="home-hero3">
      <div class="home-container05">
        <span class="home-text26">
          <span>SHARE REVIEWS</span>
          <br />
        </span>
        <h1 class="home-text29">
          SHOW YOUR BEST REVIEWS ON YOUR WEBSITE AND SOCIAL MEDIA CHANNELS.
        </h1>
        <span class="home-text30">
          <span>
            Create a carousel or feed of your best reviews and easily post them on
            your site.
          </span>
          <br />
          <br />
          <span>
            Share the best reviews on your Facebook or Instagram page via
            customizable templates.
          </span>
          <br />
          <br />
          <span>
            Schedule the posting of reviews on social to create your own
            review-based marketing strategy.
          </span>
        </span>
        <a
          href="https://client.mecaca.com/subscribe/6410808d7a842740cfea825b/mecaca-reviews-monthly"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link03 button"
        >
          Start 14 days Free Trial
        </a>
      </div>
      <img
        alt="image"
        src="/group-4349%20%5B1%5D-1400w.png"
        class="home-image10"
      />
    </div>
    <div class="home-hero4">
      <div class="home-container06">
        <h1 class="home-text38">YOUR NEW REVIEW MANAGEMENT PLATFORM</h1>
        <span class="home-text39">
          <span>STOP WASTING TIME TO MANAGE YOUR REVIEWS.</span>
          <span><span v-html="rawb5fx"></span></span>
          <span><span v-html="raw3n5t"></span></span>
        </span>
      </div>
      <img
        alt="image"
        src="/group-4295-4%20%5B1%5D-1500h.png"
        class="home-image11"
      />
      <h1 class="home-text43">START TODAY</h1>
      <a
        href="https://client.mecaca.com/subscribe/6410808d7a842740cfea825b/mecaca-reviews-monthly"
        target="_blank"
        rel="noreferrer noopener"
        class="home-link04 button"
      >
        Start 14 days Free Trial
      </a>
    </div>
    <footer class="home-footer">
      <div class="home-container07">
        <div class="home-logo">
          <img
            alt="logo"
            src="/external/group%2024-200h.png"
            class="home-image12"
          />
          <span class="home-text44">
            Easily manage and monitor your online reviews with MECACA / REVIEWS.
            Collect feedback from Google, Facebook, and other platforms to improve
            your business reputation and customer satisfaction.
          </span>
        </div>
        <div class="home-container08">
          <div class="home-container09">
            <div class="home-product-container">
              <span class="home-text45">Product</span>
              <a
                href="https://mecaca.com/digital-marketing-software/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link05"
              >
                MarTech
              </a>
              <a
                href="https://mecaca.com/pricing/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link06"
              >
                Pricing
              </a>
              <span class="home-text46">Tutorials</span>
              <span class="home-text47">Releases</span>
            </div>
            <div class="home-company-container">
              <span class="home-text48">Company</span>
              <a
                href="https://mecaca.com/about-our-company/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link07"
              >
                About
              </a>
              <span class="home-text49">Careers</span>
              <a
                href="https://mecaca.com/contact-us/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link08"
              >
                Contact
              </a>
              <a
                href="https://blog.mecaca.com/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link09"
              >
                Blog
              </a>
            </div>
            <div class="home-contact">
              <span class="home-text50">Contact Us</span>
              <span class="home-text51">marketing@mecaca.com</span>
              <span class="home-text52">+603 5626 0110</span>
              <div class="home-socials">
                <span class="home-text53">Follow Us</span>
              </div>
              <div class="home-icon-group">
                <a
                  href="https://www.facebook.com/mecaca.global.network/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link10"
                >
                  <svg viewBox="0 0 602.2582857142856 1024" class="home-icon">
                    <path
                      d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/mecaca_global_network/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link11"
                >
                  <svg viewBox="0 0 877.7142857142857 1024" class="home-icon2">
                    <path
                      d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC0_UznHEntAhpU9WllCj9HQ/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link12"
                >
                  <svg viewBox="0 0 1024 1024" class="home-icon4">
                    <path
                      d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/MECACA_Official/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link13"
                >
                  <svg viewBox="0 0 950.8571428571428 1024" class="home-icon6">
                    <path
                      d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-separator"></div>
      <span class="home-text54">
        2023 © MECACA / REVIEWS , All Rights Reserved.
      </span>
    </footer>
  </div>
</template>

<script>
import NavigationLinks from '../components/navigation-links'

export default {
  name: 'Home',
  components: {
    NavigationLinks,
  },
  data() {
    return {
      rawbnqo: ' ',
      rawf1w9: ' ',
      rawb5fx: ' ',
      raw3n5t: ' ',
    }
  },
  metaInfo: {
    title: 'Boost Your Online Reputation with MECACA / REVIEWS',
    meta: [
      {
        name: 'description',
        content:
          'Easily manage and monitor your online reviews with MECACA / REVIEWS. Collect feedback from Google, Facebook, and other more to improve your business reputation.',
      },
      {
        property: 'og:title',
        content: 'Boost Your Online Reputation with MECACA / REVIEWS',
      },
      {
        property: 'og:description',
        content:
          'Easily manage and monitor your online reviews with MECACA / REVIEWS. Collect feedback from Google, Facebook, and other more to improve your business reputation.',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image {
  width: var(--dl-size-size-xlarge);
  height: 100%;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-register {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xxlarge);
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.home-burger-menu {
  display: none;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 40vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container01 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-heading {
  font-size: 2rem;
  max-width: 450px;
}
.home-text {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btn-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  color: var(--dl-color-gray-white);
  width: 100%;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-link:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.home-image01 {
  width: 600px;
  object-fit: cover;
}
.home-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.home-text05 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container02 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.home-image02 {
  width: var(--dl-size-size-xlarge);
  object-fit: cover;
}
.home-image03 {
  width: var(--dl-size-size-xlarge);
  object-fit: cover;
}
.home-image04 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.home-image05 {
  width: var(--dl-size-size-xlarge);
  object-fit: cover;
}
.home-image06 {
  width: var(--dl-size-size-xlarge);
  object-fit: cover;
}
.home-image07 {
  width: var(--dl-size-size-xlarge);
  object-fit: cover;
}
.home-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container03 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text06 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text07 {
  font-size: 2rem;
  max-width: 450px;
}
.home-text08 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-link01 {
  color: var(--dl-color-gray-white);
  width: 100%;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-link01:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.home-image08 {
  width: 600px;
  align-self: flex-end;
  object-fit: cover;
}
.home-hero2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image09 {
  width: 600px;
  align-self: flex-end;
  object-fit: cover;
}
.home-container04 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text16 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text17 {
  font-size: 2rem;
  max-width: 450px;
  align-self: stretch;
}
.home-text18 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-link02 {
  color: var(--dl-color-gray-white);
  width: 100%;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-link02:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.home-hero3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container05 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text26 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text29 {
  font-size: 2rem;
  max-width: 450px;
}
.home-text30 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-link03 {
  color: var(--dl-color-gray-white);
  width: 100%;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-link03:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.home-image10 {
  width: 600px;
  align-self: flex-end;
  object-fit: cover;
}
.home-hero4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 130vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}
.home-container06 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text38 {
  font-size: 3rem;
}
.home-text39 {
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-image11 {
  width: 100%;
  margin-top: var(--dl-space-space-twounits);
}
.home-text43 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-link04 {
  color: var(--dl-color-gray-white);
  width: 550px;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-link04:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
  background-color: #000000;
}
.home-container07 {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image12 {
  width: var(--dl-size-size-large);
  height: auto;
}
.home-text44 {
  color: var(--dl-color-gray-white);
  width: 100%;
  margin-top: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-sixunits);
}
.home-container08 {
  width: 50%;
  display: flex;
}
.home-container09 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
}
.home-product-container {
  width: var(--dl-size-size-xlarge);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text45 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link05 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link06 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text46 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text47 {
  color: var(--dl-color-gray-white);
}
.home-company-container {
  width: var(--dl-size-size-xlarge);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text48 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link07 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text49 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-link08 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link09 {
  color: var(--dl-color-gray-white);
  text-decoration: none;
}
.home-contact {
  width: var(--dl-size-size-xlarge);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text50 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text51 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text52 {
  color: var(--dl-color-gray-white);
}
.home-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text53 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-icon-group {
  fill: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link10 {
  display: contents;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link11 {
  display: contents;
}
.home-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link12 {
  display: contents;
}
.home-icon4 {
  width: var(--dl-size-size-xsmall);
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link13 {
  display: contents;
}
.home-icon6 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}
.home-text54 {
  color: var(--dl-color-gray-white);
  align-self: center;
}
@media(max-width: 1200px) {
  .home-text05 {
    font-size: 1.5rem;
  }
  .home-text38 {
    align-self: center;
    text-align: center;
  }
  .home-container07 {
    width: 100%;
    align-self: flex-start;
  }
  .home-logo {
    width: 50%;
    padding-right: var(--dl-space-space-sixunits);
  }
  .home-text44 {
    color: var(--dl-color-gray-white);
    align-self: flex-start;
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-container08 {
    width: 50%;
  }
  .home-container09 {
    gap: 0;
    width: auto;
  }
  .home-contact {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-heading {
    text-align: center;
  }
  .home-text {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features {
    align-items: stretch;
  }
  .home-text05 {
    align-self: center;
  }
  .home-container02 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-wrap: wrap;
  }
  .home-hero1 {
    flex-direction: column;
  }
  .home-container03 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text07 {
    max-width: 800px;
    text-align: center;
  }
  .home-text08 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-image08 {
    width: 100%;
  }
  .home-hero2 {
    flex-direction: column;
  }
  .home-image09 {
    width: 100%;
  }
  .home-container04 {
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text17 {
    align-self: center;
    text-align: center;
  }
  .home-text18 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-hero3 {
    flex-direction: column;
  }
  .home-container05 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text29 {
    text-align: center;
  }
  .home-text30 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-image10 {
    width: 100%;
  }
  .home-hero4 {
    flex-direction: column;
  }
  .home-container06 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text38 {
    text-align: center;
  }
  .home-text39 {
    text-align: center;
  }
  .home-image11 {
    width: 80%;
  }
  .home-footer {
    flex-direction: column;
  }
  .home-container07 {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-logo {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image01 {
    width: 80%;
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container03 {
    align-self: center;
    align-items: flex-start;
  }
  .home-text06 {
    align-self: center;
  }
  .home-text07 {
    align-self: center;
    text-align: center;
  }
  .home-text08 {
    text-align: center;
    padding-left: 0px;
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-image08 {
    width: 100%;
    align-self: center;
  }
  .home-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image09 {
    width: 100%;
    align-self: center;
  }
  .home-container04 {
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
  }
  .home-text16 {
    align-self: center;
    text-align: center;
  }
  .home-text17 {
    align-self: center;
    text-align: center;
  }
  .home-text18 {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-link02 {
    align-self: center;
  }
  .home-hero3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container05 {
    align-self: center;
    align-items: flex-start;
  }
  .home-text26 {
    align-self: center;
    text-align: center;
  }
  .home-text29 {
    align-self: center;
  }
  .home-text30 {
    align-self: center;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image10 {
    width: 80%;
    align-self: center;
  }
  .home-hero4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-logo {
    align-items: center;
  }
  .home-image12 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text44 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-image {
    width: var(--dl-size-size-xlarge);
    height: auto;
  }
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text {
    font-size: 18px;
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-image01 {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container02 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-image04 {
    width: var(--dl-size-size-xlarge);
  }
  .home-image05 {
    width: var(--dl-size-size-xlarge);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text06 {
    font-size: 18px;
    align-self: center;
    text-align: center;
  }
  .home-text07 {
    font-size: 1.5rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text08 {
    font-size: 18px;
    text-align: left;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-link01 {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .home-image08 {
    align-self: center;
  }
  .home-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image09 {
    align-self: center;
  }
  .home-container04 {
    padding-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text16 {
    font-size: 18px;
    align-self: center;
  }
  .home-text17 {
    font-size: 1.5rem;
    align-self: center;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text18 {
    font-size: 18px;
    text-align: left;
  }
  .home-link02 {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .home-hero3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container05 {
    padding-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text26 {
    font-size: 18px;
    align-self: center;
  }
  .home-text29 {
    font-size: 1.5rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text30 {
    font-size: 18px;
    text-align: left;
  }
  .home-link03 {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .home-image10 {
    align-self: center;
  }
  .home-hero4 {
    min-height: 80vh;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-container06 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text38 {
    font-size: 2rem;
  }
  .home-text39 {
    font-size: 18px;
  }
  .home-image11 {
    width: 100%;
  }
  .home-link04 {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-container07 {
    width: 100%;
  }
  .home-logo {
    width: auto;
    align-items: center;
    padding-right: 0px;
  }
  .home-image12 {
    width: var(--dl-size-size-large);
    margin-bottom: 0px;
  }
  .home-text44 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container08 {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .home-container09 {
    flex-wrap: wrap;
    align-self: stretch;
    justify-content: space-between;
  }
  .home-product-container {
    width: 50%;
  }
  .home-link05 {
    color: var(--dl-color-gray-white);
  }
  .home-company-container {
    width: 50%;
    padding-right: var(--dl-space-space-unit);
  }
  .home-contact {
    width: auto;
    margin-top: var(--dl-space-space-threeunits);
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-text53 {
    margin-top: var(--dl-space-space-unit);
  }
  .home-icon {
    margin-right: var(--dl-space-space-unit);
  }
  .home-icon4 {
    width: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-unit);
  }
  .home-text54 {
    font-size: 12px;
    text-align: center;
  }
}
</style>
